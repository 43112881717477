<template>
  <div class="row justify-content-center">
    <div class="card col-sm-12 col-md-12">
      <div>
        <div class="title head">
          <p class="h2" style="color:#558cef">
            รอคืนทรัพย์สิน
          </p>
        </div>
        <hr/>
        <!-- search input -->
        <div class="custom-search row justify-content-end">
          <b-form-group class="col-12 col-md-6">
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
              <b-button variant="gradient-secondary">ค้นหา</b-button>
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Name -->
            <span v-if="props.column.field === 'fullName'" class="text-nowrap">
              <b-avatar :src="props.row.avatar" class="mx-1" />
              <span class="text-nowrap">{{ props.row.fullName }}</span>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-button
                  variant="outline-primary"
                  class="btn-icon mr-1"
                  @click="$router.push({ name: 'inreturnequipment' })"
                >
                  <feather-icon icon="EyeIcon" size="16" class="mx-1" />
                </b-button>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3', '5', '10']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap">of {{ props.total }} entries</span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

export default {
  components: {
    BButton,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },

  data() {
    return {
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: 'กลุ่มวัสดุ',
          field: 'กลุ่มวัสดุ',
        },
        {
          label: 'หมายเลขวัสดุ',
          field: 'หมายเลขวัสดุ',
        },
        {
          label: 'ชื่อวัสดุ',
          field: 'ชื่อวัสดุ',
        },
        {
          label: 'หน่วยนับ',
          field: 'หน่วยนับ',
        },
        {
          label: 'ราคาต่อหน่วย',
          field: 'ราคาต่อหน่วย',
        },
        {
          label: 'รอเบิก',
          field: 'รอเบิก',
        },
        {
          label: 'เบิก',
          field: 'เบิก',
        },
        {
          label: 'คงเหลือ',
          field: 'คงเหลือ',
        },
        {
          label: '',
          field: 'action',
        },
      ],
      rows: [
        {
          กลุ่มวัสดุ: 'IM01',
          หมายเลขวัสดุ: 'IM010001',
          ชื่อวัสดุ: 'กรรไกร',
          หน่วยนับ: 'อัน',
          ราคาต่อหน่วย: '60.00',
          รอเบิก: '5',
          เบิก: '0',
          คงเหลือ: '5',
          status: 'รับเข้า',
        },
        {
          กลุ่มวัสดุ: 'IM02',
          หมายเลขวัสดุ: 'IM020002',
          ชื่อวัสดุ: 'กระดาษชำระแบบม้วน',
          หน่วยนับ: 'ม้วน',
          ราคาต่อหน่วย: '12.00',
          รอเบิก: '24',
          เบิก: '0',
          คงเหลือ: '24',
          status: 'รับเข้า',
        },
        {
          กลุ่มวัสดุ: 'IM03',
          หมายเลขวัสดุ: 'IM030003',
          ชื่อวัสดุ: 'เม้าส์คอมพิวเตอร์',
          หน่วยนับ: 'อัน',
          ราคาต่อหน่วย: '199.00',
          รอเบิก: '12',
          เบิก: '0',
          คงเหลือ: '12',
          status: 'รับเข้า',
        },
      ],
      searchTerm: '',
      
    }
  },
  computed: {
  
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
